<template>
  <div v-if="loading" class="container">
    <div class="top_container">
      <a-icon @click="$router.go(-1)" class="back_btn" type="left" />
      <span>评价</span>
    </div>
    <a-form-model class="form_container">
      <a-form-model-item :label="(order.language_data && order.language_data['single.name'] ? order.language_data['single.name'] : '产品' ) + '名'">
        <div v-if="!order.work_relation_list || order.work_relation_list.length === 0">无</div>
        <div v-else style="display: flex; flex-direction: column; min-height: 40px;justify-content: center;">
          <div v-for="item of order.work_relation_list" :key="item.id" style="line-height: 20px;">
            {{ (item.goods ? item.goods.name : '') | isEmpty }}<template v-if="item.single">【{{ item.single.code }}】</template>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item :label="order.language_data && order.language_data['workorder.faultlist'] ? order.language_data['workorder.faultlist'] : '故障类别'">
        <template v-if="!order.fault_list || order.fault_list.length === 0">无</template>
        <div v-else style="display: flex; flex-direction: column; min-height: 40px;justify-content: center;">
          <div v-for="(item,index) in order.fault_list" :key="index" style="line-height: 20px;">
            {{ item.fault1_category ? item.fault1_category.name : '-' }}{{ item.fault2_category ? '/' +item.fault2_category.name : '' }}{{ item.fault3_category ? '/' + item.fault3_category.name : '' }}
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item :label="order.language_data && order.language_data['workorder.content'] ? order.language_data['workorder.content'] : '故障描述'">
        {{ order.content | isEmpty }}
      </a-form-model-item>
      <a-form-model-item label="处理人">
        {{ handler | isEmpty }}
      </a-form-model-item>
      <a-form-model-item label="处理结果">
        {{ order.finish_content | isEmpty }}
      </a-form-model-item>
    </a-form-model>
    <a-form-model style="margin-top: 10px" ref="form" :model="form" :rules="rules" class="form_container">
      <a-form-model-item label="评价">
        <div class="evaluate_box">
          <img v-if="evaluate === 'good'" src="@/assets/image/good_checked.png" />
          <img @click="setEvaluate('good')" v-else src="@/assets/image/good.png" />
          <img v-if="evaluate === 'normal'" src="@/assets/image/normal_checked.png" />
          <img @click="setEvaluate('normal')" v-else src="@/assets/image/normal.png" />
          <img v-if="evaluate === 'bad'" src="@/assets/image/bad_checked.png" />
          <img @click="setEvaluate('bad')" v-else src="@/assets/image/bad.png" />
        </div>
      </a-form-model-item>
      <a-textarea v-model="content" :disabled="readOnly" style="padding-left: 10px" placeholder="请输入评价内容" :rows="4" />
    </a-form-model>
    <div style="padding: 20px;text-align: center">
      <a-button v-if="!readOnly" type="primary" @click="onSubmit">提交</a-button>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request';
export default {
  data () {
    return {
      form: {},
      rules: {},
      order: {},
      handler: '',
      loading: false,
      evaluate: '',
      content: '',
      id: '',
      readOnly: false
    }
  },
  created: function () {
    const id = this.$route.params.id
    if (!isNaN(id)) {
      this.id = this.$route.params.id
      request({
        url: 'work_order/auto_visit_detail',
        data: { id: id }
      }).then(r => {
        this.order = r.data.data
        this.handler = r.data.data.handlers.map(x => x.user.real_name).join('、')
        this.loading = true
        if (r.data.data.auto_visit_time) {
          this.readOnly = true
          switch (r.data.data.score) {
            case 3:
              this.evaluate = 'bad'
              break;
            case 2:
              this.evaluate = 'normal'
              break;
            case 1:
              this.evaluate = 'good'
              break;
          }
          this.content = r.data.data.auto_visit_content
        }
      })
    }
  },
  methods: {
    setEvaluate (evaluate) {
      if (!this.readOnly) {
        this.evaluate = evaluate
      }
    },
    onSubmit () {
      let score = 0
      switch (this.evaluate) {
        case 'bad':
          score = 3
          break;
        case 'normal':
          score = 2
          break;
        case 'good':
          score = 1
          break;
      }
      this.$message.config({
        top: '60px'
      })
      if (score === 0) {
        this.$message.error('请选择评价')
        return false
      }
      if (this.content === '') {
        this.$message.error('请输入评价内容')
        return false
      }
      if (this.content.length > 255) {
        this.$message.error('长度超出限制')
        return false
      }
      const p = { score: score, remark: this.content, id: this.id }
      request({
        url: 'work_order/auto_visit',
        data: p
      }).then(r => {
        if (r.data.success) {
          alert('提交成功')
          window.location.reload()
        } else {
          alert('网络异常,请稍后重试')
        }
      }).catch(() => {
        alert('网络异常,请稍后重试')
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import "../assets/css/form.less";
.container{
  padding: 8px;
  padding-top: 58px;
  .form_container{
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
  }
  .evaluate_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      width: 25%;
    }
  }
  .ant-input[disabled]{
    background-color: #fff;
  }
}
</style>
